.TrailerHomeHeaderItem {

    .TrailerHomeHeaderVideo {
        width: 1000px;
        height: 588px;
    }
}
@media screen and (max-width: 1100px) {
    .TrailerHomeHeaderItem {
        .TrailerHomeHeaderVideo {
            width: 90%;
            height: 40vh;
        }
    }
}