.MovieHomeHeader {
    height: 100vh;
    position: relative;
    .BackgroundPicture {
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: -1;
    }
    .MovieHeader {
        padding: 40px 140px 40px 140px;
        position: absolute;
        display: flex;
        top: 50%;
        transform: translateY(-50%);
        max-width: 1000px;
        .MovieDetails {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .MovieTitle {
                font-size: 3em;
                margin-bottom: 40px;
            }
            .Overview {
                font-weight: 500;
            }
            .BtnBox {
                margin-top: 40px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .MovieHeaderBtn {
                    padding: 10px 25px;
                    border: white 2px solid;
                    border-radius: 20px;
                    margin-right: 25px;
                    font-weight: 600;
                }
                .NavMovieItem {
                    text-decoration: none;
                    color: white;
                    .MoreDetailsBtn {
    
                    }
                }
                .BaBtn {
                    background: red;
                    border: red;
                    box-shadow: 0 1px 20px red;
                }
            }
            .TrailerHomeHeaderBox {
                display: none;
                position: fixed;
                flex-direction: column;
                transition: all 5000ms linear;
                .NavCloseBtn {
                    align-self: flex-end;
                    margin-bottom: 10px;
                    .CloseBtn {
                        filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(129deg) brightness(108%) contrast(101%);
                        width: 32px;
                    }
                }
            }
        }
        .MoviePoster {
            width: 300px;
            height: 450px;
            border-radius: 10px;
            margin-left: 20px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .MovieHomeHeader {
        .MovieHeader {
            padding: 0px 20px 40px 20px;
            .MovieDetails {
                align-items: center;
                .MovieTitle {
                    text-align: center;
                }
            }
            .MoviePoster {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 465px) {
    .MovieHomeHeader {
        .MovieHeader {
            .MovieDetails {
                height: 80vh;
                .MovieTitle {
                    font-size: 2.5em;
                }
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .MovieHomeHeader {
        .MovieHeader {
            padding: 160px 20px 40px 20px;
            .MovieDetails {
                .BtnBox {
                    height: 15%;
                    flex-wrap: nowrap;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}

@media screen and (max-height: 600px) {
    .MovieHomeHeader {
        .MovieHeader {
            padding: 250px 20px 40px 20px;
        }
    }
}