.SimilarSeriesBox {
    padding: 40px 140px 40px 140px;
    .HeaderSlider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px white;
        padding-bottom: 10px;
        .ShowMoreBtn {
            border: 2px solid white;
            font-weight: 500;
            padding: 5px 15px;
            border-radius: 20px;
            &:hover {
                background: white;
                color: black;
            }
        }
    }
    .SimilarSeriesList {
        list-style: none;
        display: flex;
        .swiper-slide {
            width: auto;
        }
    }
}

@media screen and (max-width: 1100px) {
    .SimilarSeriesBox {
        padding: 40px 10px 40px 10px;
    }
}