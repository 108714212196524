.TrailerItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    .TrailerName {
        margin-bottom: 10px;
        text-align: center;
    }
    .TrailerVideo {
        width: 1000px;
        height: 588px;
    }
}
@media screen and (max-width: 1100px) {
    .TrailerItem {
        .TrailerName {
            
        }
        .TrailerVideo {
            width: 90%;
            height: 40vh;
        }
    }
}